import { Route, Routes } from "react-router-dom";
import { Backstage } from "../components/Backstage";
import { Display } from "../components/Display";
import { EntranceTicketGenerator } from "../components/EntranceTicketGenerator";
import { Error } from "../components/Error";
import Feedback from "../components/Feedback";
import { MintOracle } from "../components/MintOracle";
import { VerifyOracle } from "../components/VerifyOracle";
import ArtistComponent from "../components/contentElements/ArtistsComponent";
import Account from "../components/Account";

type Props = { windowHeight: number };

export const RouteMapping = (props: Props) => {
  return (
    <Routes>
      <Route path="/v0/account" element={<Account></Account>}></Route>
      <Route
        path="/v0/artist"
        element={<ArtistComponent></ArtistComponent>}
      ></Route>
      <Route path="/v0/verify" element={<VerifyOracle></VerifyOracle>}></Route>
      <Route path="/v0/backstage" element={<Backstage></Backstage>}></Route>
      <Route
        path="/v0/verify/entrance"
        element={<VerifyOracle></VerifyOracle>}
      ></Route>
      <Route
        path="/v0/mint"
        element={
          <Display initEventData={true}>
            <MintOracle></MintOracle>
          </Display>
        }
      ></Route>
      <Route
        path="/v0/oracle/generate"
        element={<EntranceTicketGenerator></EntranceTicketGenerator>}
      ></Route>
      <Route path="/error" element={<Error></Error>}></Route>
      <Route path="/feedback" element={<Feedback></Feedback>}></Route>
    </Routes>
  );
};

export default RouteMapping;
