import { Redeem } from "@mui/icons-material";
import { Box, Button, Link, Typography } from "@mui/material";
import { MintCvt, OrderResult } from "../../data/dto";
import { CvtInfo } from "../CvtInfo";
import { ItemHeader } from "./ItemHeader";

type SummaryProps = {
  cvt: MintCvt;
  isActive: boolean;
  orderResult: OrderResult;
  isMobile: boolean;
};

export const Summary = (props: SummaryProps) => {
  function downloadImage(signature: string) {
    if (props.cvt.chain && props.cvt.cvtId) {
      fetch(process.env.REACT_APP_PILOT_BE_URL + "/v0/tickets/entrance/pdf", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          chain: props.cvt.chain,
          clientId: props.orderResult.clientId,
          cvtId: "" + props.cvt.cvtId,
          smartContractAddress: props.cvt.smartContractAddress,
          smartContractVersion: props.cvt.smartContractVersion,
          signature: props.orderResult.signature,
        }),
      }).then((response) => {
        //TODO: i don t know what i am doing haha
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "ticket" + props.cvt.cvtId + ".pdf";
          a.click();
        });
      });
    }
  }

  return (
    <Box sx={{ width: "100%" }}>
      <ItemHeader
        activeDesc="Order Summary"
        isActive={props.isActive}
        notActiveDesc={"Order Summary"}
      ></ItemHeader>
      {props.isActive ? (
        <Box sx={{ p: 3, textAlign: "center" }}>
          <Redeem sx={{ fontSize: 100 }}></Redeem>
          <Typography variant="h6" mt={2} textAlign={"center"}>
            Your NFT Ticket has been successfully transfered to your wallet:
          </Typography>
          <Typography variant="h6" color="primary.main">
            {props.orderResult.clientId}
          </Typography>{" "}
          {props.isMobile ? (
            <Typography variant="h6">
              In order to see your NFT in your wallet, select Wallet in the
              MetaMask Menu and in the NFT Tab you can select import NFT. Here
              you have to enter the SmartContract Address and your Token Id.
            </Typography>
          ) : (
            <Typography variant="h6">
              View your NFT by accessing your MetaMask portfolio.{" "}
            </Typography>
          )}
          {!props.isMobile ? (
            <Link variant="h6" href={"https://portfolio.metamask.io/?tab=nfts"}>
              {" "}
              https://portfolio.metamask.io/
            </Link>
          ) : null}
          <Typography>
            Securely resell your Ticket through{" "}
            <Link href={"https://opensea.io"}>Opensea.io</Link>{" "}
          </Typography>
          <CvtInfo collapsed={!props.isMobile} cvt={props.cvt}></CvtInfo>
          <Typography mt={4}>
            Your Entrance Ticket has been sent to you via email, alternatively
            download it now!
          </Typography>
          <Button
            fullWidth={true}
            variant={"contained"}
            onClick={() => downloadImage("")}
            sx={{ mt: 2 }}
          >
            download ticket
          </Button>
        </Box>
      ) : null}
    </Box>
  );
};
