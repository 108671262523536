import { Box, Container, Link, Typography } from "@mui/material";
import React from "react";
import { handleGAButtonClick } from "../shared:0.0.2/analytics/GA";

const Feedback = () => {
  return (
    <Container
      maxWidth="sm"
      sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <Typography mt={2} variant="h3" textAlign={"center"}>
        Accounts are coming soon, stay posted!
      </Typography>
      <Typography variant="h6" mt={2} textAlign={"center"}>
        Give us feedback now, so we can create the best platform for you:
      </Typography>
      <Link
        mt={2}
        href="https://docs.google.com/forms/d/e/1FAIpQLSdoXySvTQV3_76HKCYDKDz3aJu71TQjopQdKv1Axbhj0yrXsw/viewform"
        target="_blank"
        onClick={() => handleGAButtonClick("User", "Feedback Open")}
      >
        {" "}
        <Typography color={"primary"} variant="h6">
          Feedback Form
        </Typography>
      </Link>
    </Container>
  );
};

export default Feedback;
