// EventContext.tsx
import { createContext, useState, ReactNode, ReactElement } from "react";
import { EventData } from "../types/dto";

type EventHook = {
  event: EventData | undefined;
  setEvent: (newEvent: EventData) => void;
};

// Create a context object
export const EventContext = createContext<EventHook>({
  event: undefined,
  setEvent: () => {},
});

type Props = {
  children: ReactNode;
};
// Component that provides the context value
export const EventContextProvider: React.FC<Props> = ({ children }: Props) => {
  const [event, setEvent] = useState<EventData | undefined>(undefined);
  return (
    <EventContext.Provider value={{ event, setEvent }}>
      {children}
    </EventContext.Provider>
  );
};
