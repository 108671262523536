import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Artist, PreviousArtistLocationState } from "../../data/bll";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React from "react";
import { ArrowBackIos } from "@mui/icons-material";
import { blackTheme } from "../../shared:0.0.2/themes/blackTheme";
import SoundCloudComponent from "./SoundCloudComponent";

const ArtistComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const goBack = () => {
    // Navigate back to the previous page
    location.state &&
      (location.state as PreviousArtistLocationState).from &&
      navigate((location.state as PreviousArtistLocationState).from);
  };

  const [artist, setArtist] = React.useState<Artist | null>(
    location.state && (location.state as PreviousArtistLocationState).artist
      ? (location.state as PreviousArtistLocationState).artist
      : null
  );

  if (!artist) {
    return (
      <Container maxWidth="sm">
        <Typography variant="h1">This Artist is coming soon!</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <Button onClick={goBack} sx={{ mb: 2 }}>
        <ArrowBackIos></ArrowBackIos>
        <Typography color={"primary"}>Back</Typography>
      </Button>
      {artist.image ? (
        <Avatar
          sx={{ width: 50, height: 50 }}
          alt={artist.name}
          src={artist.image}
        ></Avatar>
      ) : (
        <Avatar sx={{ width: 50, height: 50 }}>
          {artist.name.slice(0, 2)}
        </Avatar>
      )}
      <Typography variant="h2" mt={2}>
        {artist.name}
      </Typography>
      <Typography>{artist.description}</Typography>
      {artist.trackIds.map((trackId, key) => (
        <Box key={key}>
          <SoundCloudComponent
            trackId={trackId}
            description={null}
            name={null}
          ></SoundCloudComponent>
        </Box>
      ))}
    </Container>
  );
};

export default ArtistComponent;
