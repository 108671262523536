import { PauseCircle, PlayCircle } from "@mui/icons-material";
import {
  Box,
  ButtonBase,
  Slider,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { blackTheme } from "../../shared:0.0.2/themes/blackTheme";
import MediaShareButton from "../../shared:0.0.2/util/MediaShareButton";
import { Disc } from "../../svg/Disc";
import { SoundCloud } from "../../svg/SoundCloud";
import { MusicSetElement } from "../../data/bll";
import {
  handleGAButtonClick,
  handleGAShare,
} from "../../shared:0.0.2/analytics/GA";
import Exclusive from "./Exclusive";

const SoundPlayer = ({
  url,
  soundCloudProfile,
  soundCloudUrl,
  name,
  description,
  exclusive,
}: MusicSetElement & { exclusive: boolean }) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    let rotationInterval: any = null;

    if (isPlaying) {
      rotationInterval = setInterval(() => {
        setRotation((prevRotation) => prevRotation + 1);
      }, 20); // Adjust the rotation speed as needed
    }

    return () => {
      clearInterval(rotationInterval);
    };
  }, [isPlaying]);

  const formatTime = (time: number) => {
    const mins = Math.floor(time / 60);
    const secs = Math.floor(time % 60);
    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const togglePlayPause = () => {
    if (!audioRef.current) return;

    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }

    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    if (!audioRef.current) return;
    const updatedProgress =
      (audioRef.current.currentTime / audioRef.current.duration) * 100;
    setProgress(updatedProgress);
    setCurrentTime(audioRef.current.currentTime);
  };

  const handleMetadata = () => {
    if (!audioRef.current) return;
    setDuration(audioRef.current.duration);
  };

  const handleSliderChange = (_: any, newValue: number | number[]) => {
    if (audioRef.current) {
      handleGAShare("User", "Soundplayer Slide", audioRef.current?.src);
    }
    if (!audioRef.current) return;
    if (Array.isArray(newValue)) return; // We don't handle range sliders here

    const newTime = (newValue * audioRef.current.duration) / 100;
    audioRef.current.currentTime = newTime;
  };

  useEffect(() => {
    const audioEl = audioRef.current;
    if (!audioEl) return;

    audioEl.addEventListener("timeupdate", handleTimeUpdate);
    audioEl.addEventListener("loadedmetadata", handleMetadata);

    return () => {
      audioEl.removeEventListener("timeupdate", handleTimeUpdate);
      audioEl.removeEventListener("loadedmetadata", handleMetadata);
    };
  }, []);

  return (
    <Box mb={2}>
      <audio ref={audioRef} src={url} preload="metadata" />
      <Box
        sx={{
          borderRadius: 5,
          backgroundColor: "background.default",
          p: 2,
          pb: 1,
          pt: 1,
          minHeight: 150,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Slider
            sx={{
              color: "green",
              "& .MuiSlider-thumb": {
                backgroundColor: "#D9D9D9",
              },
              "& .MuiSlider-track": {
                backgroundColor: "#52784F",
              },
              "& .MuiSlider-rail": {
                backgroundColor: "rgba(187, 198, 179, 1)",
              },
            }}
            value={progress}
            onChangeCommitted={handleSliderChange}
            step={0.1} // make the Slider more precise
            aria-labelledby="continuous-slider"
          />
          <Typography fontSize={12} sx={{ ml: 1, minWidth: 70 }}>
            {formatTime(currentTime)} / {formatTime(duration)}
          </Typography>
        </Box>
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "stretch",
          }}
        >
          <Box
            className="XXX"
            sx={{
              position: "absolute",
              mt: 0,
              width: 120,
              height: 120,
              borderRadius: 50,
              justifyContent: "space-around",
              alignItems: "center",
              display: "flex",
              transform: `rotate(${rotation}deg)`,
            }}
          >
            <Disc sx={{ width: 120, height: 120 }}></Disc>
          </Box>
          <Box
            sx={{
              position: "absolute",
              mt: 0,
              width: 120,
              height: 120,
              borderRadius: 50,
              justifyContent: "space-around",
              alignItems: "center",
              display: "flex",
            }}
          >
            <ButtonBase
              sx={{ backgroundColor: "white", width: 20, height: 20, p: 0 }}
              onClick={() => {
                handleGAButtonClick("User", "Soundplayer Play");
                togglePlayPause();
              }}
            >
              {!isPlaying ? (
                <PlayCircle sx={{ width: 40, height: 40, p: 0 }}></PlayCircle>
              ) : (
                <PauseCircle sx={{ fontSize: 40 }}></PauseCircle>
              )}
            </ButtonBase>
          </Box>
          <Box sx={{ width: 120 }}></Box>
          <Box sx={{ ml: 2, flex: 1 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Typography variant="h2">{name}</Typography>
              <ButtonBase
                onClick={() => {
                  handleGAShare("User", "Soundcloud", soundCloudProfile);
                  window.open(soundCloudProfile, "_blank");
                }}
              >
                <SoundCloud
                  style={{
                    marginLeft: 10,
                    width: 30,
                    height: 30,
                    alignSelf: "flex-end",
                  }}
                  fill="#FF3300"
                ></SoundCloud>
              </ButtonBase>
            </Box>
            <Typography variant="body2">{description}</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            marginLeft: "135px",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box>{exclusive ? <Exclusive></Exclusive> : null}</Box>
          {soundCloudUrl ? (
            <MediaShareButton
              download={soundCloudUrl ? false : true}
              url={soundCloudUrl ? soundCloudUrl : url}
              width={25}
              height={25}
              iconProps={{
                color: "secondary",
                sx: {},
              }}
            ></MediaShareButton>
          ) : null}
        </Box>
        <Box sx={{ marginLeft: "135px", position: "sticky", top: -10 }}> </Box>
      </Box>
    </Box>
  );
};
export default SoundPlayer;
