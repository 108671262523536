import {
  Avatar,
  Box,
  ButtonBase,
  Divider,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React from "react";
import { Artist, LineUpElement } from "../../data/bll";
import { whiteTheme } from "../../shared:0.0.2/themes/whiteTheme";
import { defaultTheme } from "../../defaultTheme";
import { blackTheme } from "../../shared:0.0.2/themes/blackTheme";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  lineUpElement: LineUpElement;
  isAll: boolean;
};

const LineUpComponent = ({ lineUpElement, isAll }: Props) => {
  const [artist, setArtist] = React.useState<Artist>();
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    fetch(lineUpElement.artistUrl + "")
      .then((res) => {
        return res.json();
      })
      .then((_artist: any) => {
        setArtist(_artist);
      });
  }, []);

  if (!artist) {
    return null;
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          backgroundColor: "background.default",
          mb: 1,
          p: 1,
          borderRadius: 2,
          alignItems: "flex-start",
        }}
      >
        <Box sx={{ flexDirection: "row", display: "flex" }}>
          <ButtonBase
            onClick={() =>
              navigate("/v0/artist", {
                state: { from: location, artist: artist },
              })
            }
          >
            {artist.image ? (
              <Avatar alt={artist.name} src={artist.image}></Avatar>
            ) : (
              <Avatar>{artist.name.slice(0, 2)}</Avatar>
            )}
          </ButtonBase>
          <Box sx={{ ml: 1 }}>
            <Typography align="left" fontWeight={"bold"}>
              {artist.name}
            </Typography>
            <Typography align="left" variant="body2">
              {isAll ? lineUpElement.stage : artist.description}
            </Typography>
          </Box>
        </Box>
        <ThemeProvider theme={blackTheme}>
          <Box
            sx={{
              backgroundColor: "background.default",
              p: 1,
              borderRadius: 2,
            }}
          >
            <Typography variant="body2">{lineUpElement.time}</Typography>
          </Box>
        </ThemeProvider>
      </Box>
    </ThemeProvider>
  );
};

export default LineUpComponent;
