import { createTheme, ThemeOptions } from "@mui/material/styles";
import { defaultTheme } from "./defaultTheme";

export const defaultThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#52784f",
      contrastText: "#FFFFFF",
    },
    secondary: { main: "#FFFFFF" },
    background: {
      default: "#3B3B3B",
    },
    text: {
      primary: "#FFFFFF",
    },
  },
};

export const blackTheme = createTheme(defaultTheme, defaultThemeOptions);
