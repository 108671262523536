import { Box, Button, TextField, Typography } from "@mui/material";
import { ethers } from "ethers";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ownerOfNFT } from "../../logic/Cvt";
import LoadingScreen from "../lib/LoadingScreen";
import { ItemHeader } from "./ItemHeader";
import { Cvt } from "../../data/dto";
import { EventContext } from "../../shared:0.0.2/frame/EventContextProvider";

type MetaMaskAccountProps = {
  isActive: boolean;
  provider: ethers.providers.Web3Provider | undefined;
  setResult: (result: any) => void;
};

export const EntranceTicket = (props: MetaMaskAccountProps) => {
  const [accounts, setAccounts] = React.useState<string[]>([]);
  const [email, setEmail] = React.useState<string>("");
  const [error, setError] = React.useState<string>("");
  const [workingOnIt, setWorkingOnIt] = React.useState<boolean>();
  const [tokenId, setTokenId] = React.useState<string>();

  const { event, setEvent } = React.useContext(EventContext)!;

  const navigate = useNavigate();
  function getAccounts() {
    props.provider
      ?.send("eth_requestAccounts", [])
      .then((signers: string[]) => {
        if (signers.length > 0) {
          setAccounts(signers);
        } else {
          setAccounts([]);
        }
      });
  }

  //MetaMask Accounts
  React.useEffect(() => {
    getAccounts();

    let _window = window as any;
    if (_window.ethereum) {
      _window.ethereum.on("accountsChanged", (_accounts: string[]) => {
        if (_accounts.length > 0) {
          setAccounts(_accounts);
        } else {
          setAccounts([]);
        }
      });
    }
  }, []);

  async function signCheckInMessage() {
    //signer?.signMessage("hello world");
    return props.provider?.getSigner().signMessage("TokenID: " + tokenId);
  }
  function isValidEmail(_email: string) {
    return /\S+@\S+\.\S+/.test(_email);
  }

  async function generateEntranceTicket() {
    if (isNaN(Number(tokenId))) {
      setError("Please enter your TokenId");
      return;
    }
    if (Number(tokenId) === 0) {
      setError("TokenId must be a Number");
      return;
    }
    if (!isValidEmail(email)) {
      setError("Not a valid Email Address");
      return;
    }
    if (event?.contractAddress && event.chain) {
      let nft: Cvt = {
        smartContractAddress: event?.contractAddress,
        smartContractVersion: "atlas",
        chain: event?.chain,
        clientId: accounts[0],
        cvtId: Number(tokenId),
        signature: "",
      };
      ownerOfNFT(nft)
        .then((res) => {
          if (res.status !== 200) {
            setError("Internal Server Error");
          }
          return res.text();
        })
        .then((valid) => {
          if (valid === "true") {
          } else {
            setError(
              "Your Wallet is not the Owner of the NFT Ticket with TokenId: " +
                tokenId
            );
            return;
          }
          signCheckInMessage().then((signature) => {
            if (signature) {
              nft.signature = signature;
            } else {
              setError("Failed to get Signature");
              return;
            }
            let _nft: any = { ...nft };
            let _cvtId = _nft.cvtId;
            delete _nft.cvtId;
            _nft = { ..._nft, cvtId: _cvtId };
            fetch(
              process.env.REACT_APP_PILOT_BE_URL +
                "/v0/tickets/entrance/pdf/email?" +
                new URLSearchParams({ email: email }),
              {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(_nft),
              }
            ).then((response) => {
              if (response.status === 200) {
                props.setResult(nft);
              } else {
                setError("Internal Server Error");
                return;
              }
            });
          });
        })
        .catch((error) => setError("Internal Server Error"));
    }
  }

  return (
    <Box sx={{ width: "100%" }}>
      <ItemHeader
        isActive={props.isActive}
        activeDesc="Generate Entrance Ticket"
        notActiveDesc="Generate Entrance Ticket"
      ></ItemHeader>
      {props.isActive ? (
        <Box sx={{ p: 3, pt: 5 }}>
          <Typography variant="h5">
            Currently you have selected the following Wallet Address, in order
            to generate a Entrance Ticket, this Wallet needs to own an NFT
            Ticket for the Event:
          </Typography>
          <Typography
            mt={5}
            color={"primary.main"}
            sx={{ wordWrap: "break-word" }}
          >
            {" "}
            <strong>{accounts[0]}</strong>
          </Typography>
          <Typography variant="h5" mt={5} mb={3}>
            Please enter your NFT Token ID.
          </Typography>
          <TextField
            required
            type={"number"}
            label={"TokenId"}
            fullWidth={true}
            value={tokenId}
            onChange={(event) => setTokenId(event.target.value)}
          ></TextField>
          <Typography variant="h5" mt={5} mb={3}>
            Please enter your Email Address.
          </Typography>
          <TextField
            required
            type={"email"}
            label={"Email"}
            fullWidth={true}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          ></TextField>
          <Typography mt={1}>
            If the Wallet holds the NFT with the TokenID we will send you a new
            Entrance Ticket, that proves Ownership of your Wallet. Please use
            this Ticket at the Entrance.
          </Typography>
          <Typography variant="h5" mt={5} mb={3}>
            Generate Entrance Ticket:
          </Typography>
          <Typography mb={3}>
            In order to convert the Ticket you have to sign a message that we
            will use for the Entrance Ticket.
          </Typography>
          <Typography color={"red"}>{error}</Typography>
          <Button
            fullWidth={true}
            variant={"contained"}
            onClick={() => generateEntranceTicket()}
          >
            {workingOnIt ? <LoadingScreen></LoadingScreen> : "Convert Ticket"}
          </Button>
        </Box>
      ) : null}
    </Box>
  );
};
