import { Box } from "@mui/material";
import { ethers } from "ethers";
import MobileDetect from "mobile-detect";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { getEventDataFromContract } from "../logic/Cvt";
import { Display } from "./Display";
import LoadingScreen from "./lib/LoadingScreen";
import { EntranceTicket } from "./mintItems/EntranceTicket";
import { EntranceTicketSummary } from "./mintItems/EntranceTicketSummary";
import { MetaMaskChain } from "./mintItems/MetaMaskChain";
import { MetaMaskDetection } from "./mintItems/MetaMaskDetection";
import { Cvt } from "../data/dto";
import { EventContext } from "../shared:0.0.2/frame/EventContextProvider";

export const EntranceTicketGenerator = () => {
  let md = new MobileDetect(window.navigator.userAgent);

  //Browser Detection
  const [isMobile] = React.useState<boolean>(
    md.os() === "iOS" || md.os() === "AndroidOS"
  );

  //MetaMask Detection
  const [provider] = React.useState(
    (window as any).ethereum !== undefined
      ? new ethers.providers.Web3Provider((window as any).ethereum, "any")
      : undefined
  );

  //MetaMask Chain Check
  const [isPolygon, setIsPolygon] = React.useState<boolean>(false);

  //Get Order Results
  const [result, setResult] = React.useState<Cvt>();

  //event SmartContractAddress info
  const [searchParams] = useSearchParams();

  const { setEvent } = React.useContext(EventContext)!;

  React.useEffect(() => {
    let smartContractAddress = searchParams.get("smartContractAddress");
    if (smartContractAddress) {
      getEventDataFromContract(smartContractAddress)
        .then((res) => res.json())
        .then((eventData) => setEvent(eventData));
    }
  }, []);

  let metaMaskDetectionIsActive = provider === undefined;
  let metaMaskChainIsActive = !metaMaskDetectionIsActive && !isPolygon;
  let convertIsActive = !metaMaskChainIsActive && isPolygon && !result;
  let summaryIsActive = !convertIsActive && result !== undefined;

  if (isMobile === undefined) {
    return <LoadingScreen></LoadingScreen>;
  }

  return (
    <Display initEventData={false}>
      <Box
        style={{
          borderRadius: 20,
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: "#52784F",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflow: "hidden",
          paddingTop: 1,
          backgroundColor: "white",
          marginBottom: 20,
        }}
      >
        <MetaMaskDetection
          isMobile={isMobile}
          isActive={metaMaskDetectionIsActive}
          os={md.os()}
        ></MetaMaskDetection>
        <MetaMaskChain
          provider={provider}
          setChainIsActive={setIsPolygon}
          isActive={metaMaskChainIsActive}
        ></MetaMaskChain>
        <EntranceTicket
          provider={provider}
          isActive={convertIsActive}
          setResult={setResult}
        ></EntranceTicket>
        <EntranceTicketSummary
          isActive={summaryIsActive}
          result={result}
          isMobile={isMobile}
        ></EntranceTicketSummary>
      </Box>
    </Display>
  );
};
