import { createTheme, ThemeOptions } from "@mui/material/styles";

export const defaultThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#52784f",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#3B3B3B",
      contrastText: "#FFFFFF",
    },
    background: {
      default: "#FDF7F2",
    },
    text: {
      primary: "#000000",
      secondary: "#3B3B3B",
    },
    action: {
      active: "#52784f",
    },
  },
  typography: {
    fontFamily: "Montserrat, sans-serif",
    h1: {
      fontWeight: 600,
      fontSize: 55,
    },
    h2: {
      fontWeight: 600,
      fontSize: 20,
    },
    h3: {
      fontWeight: 600,
      fontSize: 45,
    },
    h5: {
      fontWeight: 600,
      fontSize: 25,
    },
    body1: { fontSize: 15 },
    body2: { fontSize: 12 },
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        color: "transparent",
        elevation: 0,
      },
    },
    MuiTypography: {
      defaultProps: {
        color: "textPrimary",
      },
    },
    MuiIcon: {
      defaultProps: {
        color: "primary",
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        color: "primary",
      },
    },
  },
};

export const defaultTheme = createTheme(defaultThemeOptions);
