import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import React from "react";
import { EventContext } from "../shared:0.0.2/frame/EventContextProvider";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { PreviousLocationState } from "../data/bll";
import { ArrowBackIos, Instagram } from "@mui/icons-material";
import { getEventDataFromContract } from "../logic/Cvt";
import { EventData, EventState } from "../shared:0.0.2/types/dto";

const Account = () => {
  const { event, setEvent } = React.useContext(EventContext);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleInstagramLogin = () => {
    window.location.href =
      `https://api.instagram.com/oauth/authorize?client_id=` +
      process.env.REACT_APP_INSTAGRAM_APP_ID +
      `&redirect_uri=https://oracle.mintharbor.io/v0/account&scope=user_profile&response_type=code&state=` +
      event?.contractAddress;
  };

  const navigate = useNavigate();
  const location = useLocation();

  const goBack = () => {
    // Navigate back to the previous page
    if (location.state && (location.state as PreviousLocationState).from) {
      navigate((location.state as PreviousLocationState).from);
    }
    if (event) {
      navigate("/v0/backstage?contractAddress=" + event.contractAddress);
    }
  };

  React.useEffect(() => {
    let _contractAddress = searchParams.get("state");
    if (!event && _contractAddress) {
      getEventDataFromContract(_contractAddress)
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(response.status + ": " + response.statusText);
          } else {
            return response.json();
          }
        })
        .then((_eventData: EventData) => {
          if (
            _eventData.eventState.toString() === EventState[EventState.NONE]
          ) {
            setEvent(_eventData);
          }
        })
        .catch((error) =>
          navigate("/error", {
            state: {
              statusCode: 400,
              msg: "The event information for the Ticket could not be retrieved, indicating that the Ticket is not issued by MintHarbor",
            },
          })
        );
    }
  }, [event]);

  if (!event) {
    return <CircularProgress></CircularProgress>;
  }

  return (
    <Container maxWidth="sm">
      <Button onClick={goBack} sx={{ mb: 2 }}>
        <ArrowBackIos></ArrowBackIos>
        <Typography color={"primary"}>Back</Typography>
      </Button>
      <Typography variant="h1">Coming Soon!</Typography>
      <Typography sx={{ mt: 2 }}>
        Are you an Artist performing at {event.title}? Sign in with Instagram to
        post Stories during the Festival.
      </Typography>
      <Button variant="contained" sx={{ mt: 2 }} onClick={handleInstagramLogin}>
        <Instagram sx={{ color: "white" }}></Instagram>
        <Typography color={"text"} ml={2}>
          Sign in with Instagram
        </Typography>
      </Button>
      <Typography mt={2}>
        Are you attending {event.title}? Sign in to post to leave your favorite
        Artists a message.
      </Typography>
      <Button variant="contained" sx={{ mt: 2 }} onClick={handleInstagramLogin}>
        <Instagram sx={{ color: "white" }}></Instagram>
        <Typography color={"text"} ml={2}>
          Sign in with Instagram
        </Typography>
      </Button>
    </Container>
  );
};

export default Account;
