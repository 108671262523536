import { SvgIcon, SvgIconProps } from "@mui/material";

export const Disc = (sx: SvgIconProps) => {
  return (
    <SvgIcon {...sx} height="117" width="117" viewBox="0 0 117 117" fill="none">
      <circle cx="58.5" cy="58.5" r="58.5" fill="black" />
      <path
        d="M35.8999 95.2932C34.1599 99.0531 29.2233 99.9599 26.2606 97.0639L17.8726 88.8644C14.7 85.7631 15.8742 80.4103 20.0536 78.9216L32.9149 74.3407C35.1947 73.5287 37.7391 74.1658 39.3672 75.9562L40.3224 77.0066C41.9452 78.7912 42.3415 81.3741 41.3284 83.5632L35.8999 95.2932Z"
        fill="#D9D9D9"
      />
    </SvgIcon>
  );
};
