import { Box, Link, ThemeProvider, Typography } from "@mui/material";
import React from "react";
import { blackTheme } from "../../shared:0.0.2/themes/blackTheme";
import { Navigate, useNavigate } from "react-router-dom";
import { handleGAButtonClick } from "../../shared:0.0.2/analytics/GA";
import Exclusive from "./Exclusive";

type Props = {
  eventTitle: string;
  exclusive: boolean;
};

const Discount = ({ eventTitle, exclusive }: Props) => {
  let navigate = useNavigate();

  return (
    <ThemeProvider theme={blackTheme}>
      <Box
        sx={{ backgroundColor: "background.default", p: 2, borderRadius: 5 }}
      >
        <Typography variant="h2">
          Thanks for attending {eventTitle}, get your future Discount.
        </Typography>
        <Box sx={{ backgroundColor: "#B5B6B7", p: 1, mt: 1, borderRadius: 2 }}>
          <Typography variant="h3">XS736BB</Typography>
        </Box>
        <Typography mt={1} variant="body2">
          Coming Soon! Create an{" "}
          <Link
            onClick={() => {
              handleGAButtonClick("User", "Clicked Account");
              navigate("/feedback");
            }}
          >
            Account
          </Link>{" "}
          to stay updated.
        </Typography>
        {exclusive ? <Exclusive></Exclusive> : null}
      </Box>
    </ThemeProvider>
  );
};

export default Discount;
