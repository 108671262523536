import { Box } from "@mui/material";

const Header = () => {
  return (
    <Box
      sx={{ p: "3px", height: 26 }}
      component={"img"}
      src="/images/logos/mh_logo_medium_black.png"
    ></Box>
  );
};

export default Header;
