import { IconName } from "../types/bll";

export const footerColumns = [
  {
    name: "Socials",
    items: [
      {
        icon: IconName.INSTAGRAM,
        name: "Instagram",
        link: "https://www.instagram.com/mintharbor.io/",
      },
      {
        icon: IconName.WEBSITE,
        name: "Website",
        link: "https://mintharbor.io",
      },
      { icon: IconName.MAIL, name: "Mail", link: "mailto:info@mintharbor.io" },
    ],
  },
  {
    name: "Documents",
    items: [
      {
        icon: IconName.DOCUMENT,
        name: "Legal Notice",
        link: "https://google.com",
      },
      {
        icon: IconName.DOCUMENT,
        name: "Data Protection",
        link: "https://google.com",
      },
    ],
  },
  {
    name: "Help",
    items: [
      {
        icon: IconName.MAIL,
        name: "Mail",
        link: "mailto:info@mintharbor.iomailto:info@mintharbor.io",
      },
    ],
  },
];
