import { Wallet } from "ethers";
import React from "react";
import { useNavigate } from "react-router-dom";
import { MintCvt } from "../../data/dto";
import { verifyCvt } from "../../logic/Cvt";
import { ItemHeader } from "./ItemHeader";

type VerifyRequestProps = {
  cvt: MintCvt;
  isActive: boolean;
  setVerified: (verified: boolean) => void;
};

export const VerifyRequest = ({
  cvt,
  isActive,
  setVerified,
}: VerifyRequestProps) => {
  const navigate = useNavigate();
  React.useEffect(() => {
    if (cvt) {
      verifyCvt(cvt)
        .then((res) => {
          if (!res.ok) {
            navigate("/error", { state: { statusCode: 500 } });
          }
          return res.json();
        })
        .then((body) => {
          if (body.success) {
            let pubKey = new Wallet(cvt.secret).address;
            if (pubKey.toLowerCase() === cvt.clientId.toLowerCase()) {
              if (!body.valid) {
                navigate("/error", {
                  state: {
                    statusCode: 200,
                    msg:
                      "The ticket is invalid" +
                      (body.revert_reason ? ", " + body.revert_reason : ""),
                    cvt: cvt,
                  },
                });
              }
              console.log(body.valid);
              setVerified(true);
            } else {
              navigate("/error", {
                state: {
                  statusCode: 200,
                  msg: "The ticket is valid, but we could not prove your ownership.",
                  cvt: cvt,
                },
              });
            }
          } else {
            navigate("/error", {
              state: { statusCode: 200, msg: body.revert_reason, cvt: cvt },
            });
          }
        })
        .catch((err) => {
          navigate("/error", { state: { statusCode: 500 } });
        });
    }
  }, [cvt]);

  return (
    <ItemHeader
      isActive={isActive}
      activeDesc="Ticket is being Verified"
      notActiveDesc="Ticket is Valid"
    ></ItemHeader>
  );
};
