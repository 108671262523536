import { Cvt } from "./dto";

export enum ContentComponent {
  MUSIC_SET = "musicSet",
  IMAGES = "images",
  NFT = "nft",
  DISCOUNT = "discount",
  LINE_UP = "lineup",
  SOUNDCLOUD = "soundcloud",
}

export interface ContentElement {
  component: ContentComponent;
  data:
    | MusicSetElement
    | ImagesElement
    | NFTElement
    | undefined
    | LineUpElements
    | SoundCloudElement;
  exclusive: boolean;
}

export interface SoundCloudElement {
  trackId: string;
  description: string | null;
  name: string | null;
}

export interface NFTElement {
  type: NFTType;
}

export enum NFTType {
  UNIQUE = "unique",
  SINGLE = "single",
}

export interface NFTAttribute {
  trait_type: string;
  value: string;
}
export interface LineUpElements {
  stages: string[];
  dayElements: LineUpDay[];
  startDate: string;
  endDate: string;
}

export interface LineUpDay {
  day: string;
  elements: LineUpElement[];
}

export interface LineUpElement {
  artistUrl: string;
  time: string;
  stage: string;
}

export interface Artist {
  image: string | null;
  name: string;
  description: string;
  trackIds: string[];
}

export interface MusicSetElement {
  url: string;
  soundCloudProfile: string;
  soundCloudUrl: string | undefined;
  name: string;
  description: string;
}

export interface ImagesElement {
  baseUrl: string;
  size: number;
  title: string;
  format: string;
}

export type ColumnesText = {
  columnes: ColumnText[];
};

export type ColumnText = {
  name: string;
  links: LinkText[];
};

export type LinkText = {
  name: string;
  link: string;
};

export type ErrorState = {
  cvt: Cvt;
  msg: string;
  statusCode: number;
};

export type PreviousArtistLocationState = {
  from: Location;
  artist: Artist | null;
};

export type PreviousLocationState = {
  from: Location;
};
