export type IconLink = {
  icon: IconName;
  name: string;
  link: string;
};

export type IconLinkColumn = {
  name: string;
  items: IconLink[];
};

export enum IconName {
  WEBSITE = "website",
  INSTAGRAM = "instagram",
  FACEBOOK = "facebook",
  DOCUMENT = "document",
  MAIL = "mail",
}
