export type EventPreview = {
  name: string;
  date: string;
  state: EventState;
  id: string;
};

export interface EventData {
  price: number;

  url: string;

  maxIndex: number;

  chainId: number;

  contractAddress: string;

  chain: string;

  title: string;

  currencyCode: Currency;

  currIndex: number;

  infos: string[];

  organizer: string;

  socials: Social[];

  description: string;

  nftBenefits: string[];

  designer: Designer;

  ticketDescription: string;

  ticketType: string;

  eventState: EventState;
}

export enum Currency {
  EUR = "EUR",
  CHF = "CHF",
  USD = "USD",
}

export type Designer = {
  name: string;
  link: string;
};

export type Social = {
  name: string;
  link: string;
};

export const getColorFromEventState = (state: EventState) => {
  return state === EventState.ACTIVE
    ? "#008450"
    : state === EventState.DRAFT
    ? "#EFB700"
    : state === EventState.PAST
    ? "#DADADA"
    : "default";
};

export interface MHEvent {
  id: number;
  eventDetails: EventDetailsEmbedabble;
  ticketInfo: TicketInfoEmbedabble;
  ticketDesign: TicketDesignEmbedabble;
  smartContractAddress: string;
  eventState: EventState;
  currIndex: number;
  maxIndex?: number;
}

export interface EventDetailsEmbedabble {
  title: string;
  description: string;
  date?: string;
  time?: string;
  venueName?: string;
  venueAddress?: string;
}

export interface TicketInfoEmbedabble {
  productDescription?: string;
  pricingType: PricingType;
  pricingTable: PricingElement[];
  taxInPercentage?: number;
}

export interface TicketDesignEmbedabble {
  designerName?: string;
  designerSocials?: SocialEmbeddable[];
  ticketType?: TicketType;
}

export interface PricingElement {
  name: string;
  price: number;
  amount: number;
}

export enum PricingType {
  TIERED,
  SINGLE,
}

export enum EventState {
  DRAFT,
  ACTIVE,
  PAST,
  NONE,
}

export enum TicketType {
  UNIQUE,
  SINGLE,
}

export interface SocialEmbeddable {
  name: string;
  link: string;
}
