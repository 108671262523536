import { Box, Button, Link, Typography } from "@mui/material";
import { ethers } from "ethers";
import React from "react";
import LoadingScreen from "../lib/LoadingScreen";
import { ItemHeader } from "./ItemHeader";

type MetaMaskChainProps = {
  setChainIsActive: (active: boolean) => void;
  isActive: boolean;
  provider: ethers.providers.Web3Provider | undefined;
};

export const MetaMaskChain = (props: MetaMaskChainProps) => {
  const chainInfo = {
    chainId: "0x89",
    chainName: "Polygon Mainnet",
    rpcUrls: ["https://polygon-rpc.com/"],
    nativeCurrency: {
      decimals: 18,
      name: "Polygon",
      symbol: "MATIC",
    },
    blockExplorerUrls: ["https://polygonscan.com/"],
  };

  const [polyInstalled, setPolyInstalled] = React.useState<boolean>();

  React.useEffect(() => {
    let _window = window as any;
    if (_window.ethereum) {
      _window.ethereum.on("chainChanged", (_chainId: string) => {
        if (_chainId === "0x89") {
          props.setChainIsActive(true);
        } else {
          props.setChainIsActive(false);
        }
      });
    }
  });

  React.useEffect(() => {
    if (props.isActive) {
      getChain();
    }
  }, [props.provider, props.isActive]);

  function getChain() {
    if (props.provider) {
      return props.provider
        .send("wallet_switchEthereumChain", [{ chainId: "0x89" }])
        .then(() => {
          props.setChainIsActive(true);
          setPolyInstalled(true);
        })
        .catch((error) => {
          // This error code indicates that the chain has not been added to MetaMask
          // if it is not, then install it into the user MetaMask
          if (error.code === 4902) {
          }
          if (error.code === 4001) {
            setPolyInstalled(true);
          }
        });
    }
  }

  async function installChain() {
    if (props.provider) {
      return props.provider
        .send("wallet_addEthereumChain", [chainInfo])
        .then(() => {
          return;
        })
        .catch();
    }
  }

  return (
    <Box sx={{ width: "100%" }}>
      <ItemHeader
        isActive={props.isActive}
        activeDesc={"Please switch to the Polygon Network"}
        notActiveDesc={"Polygon Selection"}
      ></ItemHeader>
      {props.provider !== undefined && props.isActive ? (
        <Box sx={{ p: 2 }}>
          <Typography>
            Your NFT is stored on the Polygon Blockchain, in order to claim it
            you need to switch to Polygon with MetaMask
          </Typography>
          {polyInstalled ? (
            <Button
              sx={{ mt: 2 }}
              variant="contained"
              onClick={() => {
                getChain()?.then(() => installChain());
              }}
            >
              Switch to Polygon Network
            </Button>
          ) : (
            <Button
              sx={{ mt: 2 }}
              variant="contained"
              onClick={() => {
                getChain()?.then(() => installChain());
              }}
            >
              Add the Polygon Network to your Wallet
            </Button>
          )}
        </Box>
      ) : null}
    </Box>
  );
};
