import { Box, Button, TextField, Typography } from "@mui/material";
import { ethers } from "ethers";
import React from "react";
import { useNavigate } from "react-router-dom";
import { constructVoucher, mintCvt } from "../../logic/Cvt";
import { MintCvt, OrderResult } from "../../data/dto";
import LoadingScreen from "../lib/LoadingScreen";
import { ItemHeader } from "./ItemHeader";

type MetaMaskAccountProps = {
  isActive: boolean;
  provider: ethers.providers.Web3Provider | undefined;
  setOrderResult: (result: OrderResult) => void;
  cvt: MintCvt;
};

export const ConvertTicket = (props: MetaMaskAccountProps) => {
  const [accounts, setAccounts] = React.useState<string[]>([]);
  const [signature, setSignature] = React.useState<string>();
  const [email, setEmail] = React.useState<string>("");
  const [error, setError] = React.useState<string>("");
  const [workingOnIt, setWorkingOnIt] = React.useState<boolean>();

  const navigate = useNavigate();
  function getAccounts() {
    props.provider
      ?.send("eth_requestAccounts", [])
      .then((signers: string[]) => {
        if (signers.length > 0) {
          setAccounts(signers);
        } else {
          setAccounts([]);
        }
      });
  }

  //MetaMask Accounts
  React.useEffect(() => {
    getAccounts();

    let _window = window as any;
    if (_window.ethereum) {
      _window.ethereum.on("accountsChanged", (_accounts: string[]) => {
        if (_accounts.length > 0) {
          setAccounts(_accounts);
        } else {
          setAccounts([]);
        }
      });
    }
  }, []);

  async function signCheckInMessage() {
    //signer?.signMessage("hello world");
    return props.provider
      ?.getSigner()
      .signMessage("TokenID: " + props.cvt.cvtId);
  }
  function isValidEmail(_email: string) {
    return /\S+@\S+\.\S+/.test(_email);
  }

  function convertTicket() {
    if (!workingOnIt) {
      setWorkingOnIt(true);
      if (isValidEmail(email)) {
        signCheckInMessage()
          .then((signature) => {
            if (email) {
              let _body = {
                chain: props.cvt.chain,
                clientId: accounts[0],
                cvtId: "" + props.cvt.cvtId,
                smartContractAddress: props.cvt.smartContractAddress,
                smartContractVersion: props.cvt.smartContractVersion,
                signature: signature,
              };
              console.log(_body);
              if (props.cvt && signature !== undefined) {
                constructVoucher(props.cvt, accounts[0]).then((voucher) => {
                  mintCvt(props.cvt, voucher)
                    .then((res) => {
                      if (!res.ok) {
                        navigate("/error", {
                          state: {
                            statusCode: 200,
                            msg: "The server was not able to convert the Ticket",
                          },
                        });
                      }
                      return res.json();
                    })
                    .then((body) => {
                      if (!body.success) {
                        navigate("/error", {
                          state: {
                            statusCode: 200,
                            msg: body.revert_reason,
                            cvt: props.cvt,
                          },
                        });
                        return;
                      }

                      fetch(
                        process.env.REACT_APP_PILOT_BE_URL +
                          "/v0/tickets/entrance/pdf/email?" +
                          new URLSearchParams({ email: email }),
                        {
                          method: "POST",
                          headers: { "Content-Type": "application/json" },
                          body: JSON.stringify(_body),
                        }
                      )
                        .then((response) => {
                          if (response.status === 200) {
                            props.setOrderResult({
                              signature: signature,
                              txHash: body.txHash,
                              clientId: accounts[0],
                            });
                            setWorkingOnIt(false);
                          } else {
                            throw new Error();
                          }
                        })
                        .catch(() => {
                          setError("Could not send Ticket to Email Provided");
                          setWorkingOnIt(false);
                        });
                    })
                    .catch((err) => {
                      navigate("/error", {
                        state: {
                          statusCode: 500,
                          msg: "The server got itself in trouble",
                        },
                      });
                    });
                });
              } else {
                throw new Error();
              }
            } else {
              setError("Please enter your email");
              setWorkingOnIt(false);
            }
          })
          .catch(() => {
            setError("Message was not signed");
            setWorkingOnIt(false);
          });
      } else {
        setError("Please enter a valid email");
        setWorkingOnIt(false);
      }
    }
  }

  return (
    <Box sx={{ width: "100%" }}>
      <ItemHeader
        isActive={props.isActive}
        activeDesc="Convert Ticket"
        notActiveDesc="Ticket Conversion"
      ></ItemHeader>
      {props.isActive ? (
        <Box sx={{ p: 3, pt: 5 }}>
          <Typography variant="h5">
            Currently you have selected the following Wallet Address:
          </Typography>
          <Typography
            mt={5}
            color={"primary.main"}
            sx={{ wordWrap: "break-word" }}
          >
            {" "}
            <strong>{accounts[0]}</strong>
          </Typography>
          <Typography mt={1}>
            This Wallet Address identifies your Account and should be the same
            shown in MetaMask. You can change the account in MetaMask if you
            want to transfer the ticket to another wallet of yours.
          </Typography>
          <Typography variant="h5" mt={5} mb={3}>
            Please enter the Email Address you want the Entrance Ticket to be
            send too:
          </Typography>
          <TextField
            required
            type={"email"}
            label={"Email"}
            fullWidth={true}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          ></TextField>
          <Typography mt={1}>
            We will send you a new Ticket that proves ownership of the Wallet,
            please use this ticket at the Entrance of the Event.
          </Typography>
          <Typography variant="h5" mt={5} mb={3}>
            Convert your Ticket:
          </Typography>
          <Typography mb={3}>
            In order to convert the Ticket you have to sign a message that we
            will use for the Entrance Ticket.
          </Typography>
          <Typography color={"red"}>{error}</Typography>
          <Button
            fullWidth={true}
            variant={"contained"}
            onClick={() => convertTicket()}
          >
            {workingOnIt ? <LoadingScreen></LoadingScreen> : "Convert Ticket"}
          </Button>
        </Box>
      ) : null}
    </Box>
  );
};
