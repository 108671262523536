import {
  Description,
  Facebook,
  Instagram,
  Mail,
  QuestionMark,
  Web,
} from "@mui/icons-material";
import { Box } from "@mui/material";
import { IconName } from "../../types/bll";

type Props = {
  name: IconName;
};

export const IconSwitcher = (props: Props) => {
  let style = {};
  switch (props.name) {
    case "website":
      return <Web sx={style}></Web>;
    case "instagram":
      return <Instagram sx={style}></Instagram>;
    case "facebook":
      return <Facebook sx={style}></Facebook>;
    case "document":
      return <Description sx={style}></Description>;
    case "mail":
      return <Mail sx={style}></Mail>;
    default:
      return <QuestionMark sx={style}></QuestionMark>;
  }
};

export default IconSwitcher;
