import { Box, Container, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Cvt } from "../data/dto";
import { getEventData, searchParamsToCvt } from "../logic/Cvt";
import LoadingScreen from "./lib/LoadingScreen";
import { EventContext } from "../shared:0.0.2/frame/EventContextProvider";
import { EventData } from "../shared:0.0.2/types/dto";

type Props = {
  children: JSX.Element;
  initEventData: boolean;
};

export const Display = ({ children, initEventData }: Props) => {
  const { event, setEvent } = React.useContext(EventContext)!;

  const [cvt, setCvt] = React.useState<Cvt>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [screenSize, getDimension] = React.useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  let footerContext = useContext(EventContext);

  React.useEffect(() => {
    if (event && footerContext) {
      footerContext.setEvent(event);
    }
  }, [event]);

  React.useEffect(() => {
    //TODO: ignore display when we go to invalid path, otherwise on reload this will destroy everything we are just hacking at this point
    if (initEventData === true) {
      let _cvt = searchParamsToCvt(searchParams);
      if (_cvt !== null) {
        getEventData(_cvt)
          .then((response) => {
            if (response.status !== 200) {
              throw new Error(response.status + ": " + response.statusText);
            } else {
              return response.json();
            }
          })
          .then((_eventData: EventData) => {
            setEvent(_eventData);
          })
          .catch((error) =>
            navigate("/error", {
              state: {
                statusCode: 400,
                msg: "The event information for the Ticket could not be retrieved, indicating that the Ticket is not issued by MintHarbor",
              },
            })
          );
        setCvt(_cvt);
      } else {
        navigate("/error", {
          state: {
            statusCode: 400,
            msg: "Ticket is invalid because the Ticket Information cannot be resolved",
          },
        });
      }
    }
  }, [searchParams, navigate]);

  React.useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  if (!event) {
    return <LoadingScreen></LoadingScreen>;
  }

  //TODO: dynamic resizing of height

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          flex: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            height: screenSize.dynamicHeight - 40,
            flexDirection: "column",
            backgroundColor: "background.default",
            justifyContent: "space-around",
          }}
        >
          <Container
            sx={{
              p: { xs: 5, md: 5, xl: 10 },
              ph: { xs: 2, md: 5 },
              display: "flex",
              flex: 1,
              height: screenSize.dynamicHeight - 20,
              flexDirection: "column",
              backgroundColor: "secondary.main",
              borderTopRightRadius: { xs: 0, md: 20 },
              justifyContent: "space-around",
            }}
          >
            <Box
              flexDirection={"column"}
              display="flex"
              sx={{
                width: "80%",
                backgroundColor: "background.default",
                boxShadow: 10,
                borderRadius: 2,
              }}
              alignSelf="center"
              overflow="hidden"
            >
              <Box
                display="flex"
                sx={{ overflow: "hidden" }}
                width="100%"
                component="img"
                src={
                  process.env.REACT_APP_PILOT_BE_URL +
                  "/static/" +
                  event.contractAddress +
                  "/meta/poster.png"
                }
                alt="ticketImage"
                alignSelf="center"
              ></Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  p: 2,
                }}
              >
                <Typography variant="h5" pb={1}>
                  {event.title}
                </Typography>
                {event.infos.map((info, key) => (
                  <Typography key={key}>{info}</Typography>
                ))}
              </Box>
            </Box>
          </Container>
        </Box>
        <Container
          sx={{
            overflow: "auto",
            flex: 1.5,
            display: "flex",
            flexDirection: "column",
            py: { xs: 2, md: 0 },
            borderRadius: { xs: 5, md: 0 },
            borderBottomLeftRadius: { md: 20 },
            backgroundColor: "background.default",
          }}
        >
          {children}
        </Container>
      </Box>
    </Box>
  );
};
