import { Box, Divider, Typography } from "@mui/material";
import React from "react";

type ItemHeaderProps = {
  isActive: boolean;
  activeDesc: string;
  notActiveDesc: string;
};

export const ItemHeader = (props: ItemHeaderProps) => {
  if (props.isActive) {
    return (
      <Box sx={{ width: "100%" }}>
        <Box sx={{ width: "100%", p: 2, boxShadow: 10 }}>
          <Typography variant="h6">
            {props.isActive ? props.activeDesc : props.notActiveDesc}
          </Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ width: "100%", p: 2, boxShadow: 10 }}>
        <Typography variant="h6">
          {props.isActive ? props.activeDesc : props.notActiveDesc}
        </Typography>
      </Box>
    </Box>
  );
};
