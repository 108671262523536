import { Box, Typography } from "@mui/material";
import { useState } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { ImagesElement } from "../../data/bll";
import { handleGAButtonClick } from "../../shared:0.0.2/analytics/GA";
import MediaShareButton from "../../shared:0.0.2/util/MediaShareButton";
import Exclusive from "./Exclusive";
import "./ImageViewer.css";

export const ImageViewer = ({
  baseUrl,
  size,
  title,
  format,
  exclusive,
}: ImagesElement & { exclusive: boolean }) => {
  // Create a state variable to keep track of the current image
  const urls = Array.from(Array(size).keys()).map(
    (num) => baseUrl + "/" + (num + 1) + "." + format
  );

  const [currentImage, setCurrentImage] = useState(urls[0]);

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "background.default",
        pt: 2,
        pb: 2,
        borderRadius: 5,
      }}
    >
      <ImageGallery
        lazyLoad={true}
        showThumbnails={false}
        onSlide={(currentIndex) => {
          // Update the current image whenever the slide changes
          handleGAButtonClick("User", "Next Image");
          setCurrentImage(urls[currentIndex]);
        }}
        showPlayButton={false}
        items={urls.map((url) => {
          return {
            original: url,
          };
        })}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          pl: 2,
          pr: 2,
          pt: 1,
        }}
      >
        <Box>
          <Typography variant="h2">{title}</Typography>
          <Typography variant="body2">{size} items</Typography>
        </Box>
        <Box>
          <MediaShareButton
            download={true}
            url={currentImage}
            width={25}
            height={25}
            iconProps={{
              color: "secondary",
              sx: {},
            }}
          ></MediaShareButton>
        </Box>
      </Box>
      <Box sx={{ pl: 2, pr: 1 }}>
        {exclusive ? <Exclusive></Exclusive> : null}
      </Box>
    </Box>
  );
};

export default ImageViewer;
