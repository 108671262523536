import { Box, Button, Link, Typography } from "@mui/material";
import React from "react";
import LoadingScreen from "../lib/LoadingScreen";
import { ItemHeader } from "./ItemHeader";

type MetaMaskProps = {
  isMobile: boolean;
  isActive: boolean;
  os: string;
};

export const MetaMaskDetection = ({
  isMobile,
  isActive,
  os,
}: MetaMaskProps) => {
  if (!isActive) {
    return (
      <ItemHeader
        isActive={isActive}
        activeDesc="MetaMask needs to be present"
        notActiveDesc="Metamask has been found"
      ></ItemHeader>
    );
  }

  function getActiveComp() {
    if (isActive) {
      if (!isMobile)
        return (
          <Box sx={{ p: 2 }}>
            <Typography>
              In order to claim your NFT Ticket, you have to have MetaMask
              installed. Please install MetaMask and create a Wallet.
            </Typography>
            <Button
              sx={{ mt: 2 }}
              variant="contained"
              href={"https://metamask.io/download/"}
              target="_blank"
            >
              Install MetaMask Browser Extension
            </Button>
          </Box>
        );
      else {
        return (
          <Box sx={{ p: 2, width: "100%" }}>
            <Typography>
              In order to Claim your NFT Ticket head over to MetaMask. MetaMask
              is a Wallet application and your access point to Web3. MetaMask is
              non-custodial and doesn't store any user data.
            </Typography>
            <Button
              sx={{ mt: 2 }}
              variant="contained"
              href={"https://metamask.app.link/dapp/" + window.location.href}
            >
              Open with MetaMask
            </Button>
            <Typography mt={2}>
              If the link is not opened automatically in the MetaMask app,{" "}
              {os === "OS"
                ? "press and hold the button and select open in Metamask."
                : "copy the following link and open in it inside the Browser in MetaMask."}
            </Typography>
            <Link
              color="primary"
              sx={{ mt: 2, width: "100%", wordWrap: "break-word" }}
              href={window.location.href}
            >
              {window.location.href}
            </Link>
          </Box>
        );
      }
    }
  }

  if (isMobile == null) {
    return <LoadingScreen></LoadingScreen>;
  }
  return (
    <Box sx={{ width: "100%" }}>
      <ItemHeader
        isActive={isActive}
        activeDesc="MetaMask needs to be present"
        notActiveDesc="Metamask has been found"
      ></ItemHeader>
      {getActiveComp()}
    </Box>
  );
};
