import { Box, Container, Link, Typography } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import { ErrorState } from "../data/bll";
type ErrorDetail = {
  msg: string;
  headerMsg: string;
};

export const Error = () => {
  const [screenSize, getDimension] = React.useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });

  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  let { state } = useLocation();
  const [errorDetail, setErrorDetail] = React.useState<ErrorDetail>({
    headerMsg: "A Unknown Error has ocurred",
    msg: "Please,",
  });

  React.useEffect(() => {
    let errorState: ErrorState;
    errorState = state as ErrorState;
    console.log(errorState);
    if (errorState) {
      if (errorState.statusCode === 400) {
        setErrorDetail({
          headerMsg: "400: Bad Request",
          msg: errorState.msg
            ? errorState.msg
            : "We were unable to process the Verification Request",
        });
      }
      if (errorState.statusCode === 500) {
        setErrorDetail({
          headerMsg: "500: Internal Server Error",
          msg: errorState.msg
            ? errorState.msg
            : "Something went wrong please come back later",
        });
      }
      if (errorState.statusCode === 200) {
        setErrorDetail({
          headerMsg: "The Ticket is invalid",
          msg: errorState.msg
            ? errorState.msg
            : "We were not able to verify the Ticket",
        });
      }
    }
  }, []);

  function getErrorPage() {
    return (
      <Box
        sx={{
          height: screenSize.dynamicHeight - 40,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          pt: { xs: 5, md: 20 },
          pb: 5,
          backgroundColor: "background.default",
        }}
      >
        <Container maxWidth="md">
          <Typography variant="h3" textAlign={"center"}>
            {errorDetail.headerMsg}
          </Typography>
          {errorDetail.msg ? (
            <Typography mt={2} variant="body1" textAlign={"center"}>
              {errorDetail.msg}, contact{" "}
              <Link href="mailto:info@mintharbor.io">info@mintharbor.io</Link>{" "}
              for help.
            </Typography>
          ) : null}
        </Container>
        <Box
          sx={{
            height: { xs: 300 },
            flexGrow: { xs: 0, md: 1 },
            mt: 2,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "auto 100%",
            backgroundImage: "url(/images/hand_error.png)",
          }}
        ></Box>
      </Box>
    );
  }
  return <Box>{getErrorPage()}</Box>;
};
