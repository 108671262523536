import { Share } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Color,
  IconButton,
  SvgIconProps,
} from "@mui/material";
import { DefaultComponentProps } from "@mui/material/OverridableComponent";
import { handleGAShare } from "../analytics/GA";
import { useState } from "react";

interface Props {
  url: string;
  width: number;
  height: number;
  download: boolean;
  iconProps: SvgIconProps | CircularProgressProps;
}

const MediaShareButton = ({
  url,
  width,
  height,
  iconProps,
  download,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleShareClick = async () => {
    handleGAShare("User", "Share", url);
    try {
      if (download) {
        setLoading(true);
        const response = await fetch(url);
        const blob = await response.blob();
        setLoading(false);
        const fileName = url.substring(url.lastIndexOf("/") + 1);

        const file = new File([blob], fileName, { type: blob.type });

        if (navigator.canShare && navigator.canShare({ files: [file] })) {
          await navigator.share({
            files: [file],
          });
        } else {
          const shareUrl = URL.createObjectURL(file);
          window.open(shareUrl, "_blank");
        }
      } else {
        if (navigator.share) {
          await navigator.share({
            url: url,
          });
        } else {
          window.open(url, "_blank");
        }
      }
    } catch (error) {
      setLoading(false);
      console.error("Error sharing:", error);
      // Handle any errors that occur during sharing
    }
  };
  return (
    <IconButton onClick={handleShareClick}>
      {!loading ? (
        <Share color={iconProps.color} sx={{ width: width, height: height }} />
      ) : (
        <Box sx={{ width: width, height: height }}>
          <CircularProgress
            color={(iconProps as CircularProgressProps).color}
            size={height}
          ></CircularProgress>
        </Box>
      )}
    </IconButton>
  );
};

export default MediaShareButton;
