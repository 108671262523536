import { createTheme, ThemeOptions } from "@mui/material/styles";
import { defaultTheme } from "./defaultTheme";

export const defaultThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#52784f",
      contrastText: "#FFFFFF",
    },
    background: {
      default: "#FFFFFF",
    },
    text: {
      primary: "#3B3B3B",
      secondary: "#6D6D6D",
    },
  },
  components: {
    MuiContainer: {
      defaultProps: {},
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
    },
  },
};

export const whiteTheme = createTheme(defaultTheme, defaultThemeOptions);
