import ReactGA from "react-ga4";

export const handleGAButtonClick = (
  eventCategory: string,
  eventAction: string
) => {
  ReactGA.event({
    category: eventCategory,
    action: eventAction,
  });
};

export const handleGAShare = (
  eventCategory: string,
  eventAction: string,
  url: string
) => {
  ReactGA.event({
    category: eventCategory,
    action: eventAction,
    label: url,
  });
};
