import { Frame } from "./shared:0.0.2/frame/Frame";

function App() {
  if (process.env.NODE_ENV === "development") {
    const { worker } = require("./mocks/browser");

    worker.start();
  }

  return <Frame></Frame>;
}

export default App;
