import { ConfirmationNumber, Info } from "@mui/icons-material";
import { Box, ButtonBase, ThemeProvider, Typography } from "@mui/material";
import React, { useState } from "react";
import { purpleTheme } from "../../shared:0.0.2/themes/purpleTheme";

const Exclusive = () => {
  const [infoOpen, setInfoOpen] = useState<boolean>(false);

  return (
    <Box sx={{ flexDirection: "row" }}>
      <ThemeProvider theme={purpleTheme}>
        <ButtonBase onClick={() => setInfoOpen(!infoOpen)}>
          <Typography variant="body2">Exclusive {"\x00"}</Typography>
          <Typography
            variant="body2"
            sx={{
              opacity: infoOpen ? 1 : 0,
            }}
            className={infoOpen ? "exclusive-text open" : "exclusive-text"}
          >
            {infoOpen ? "for Ticket Holders!" : ""}
          </Typography>
          <ConfirmationNumber sx={{ height: 20, mr: 1 }} />
        </ButtonBase>
      </ThemeProvider>
    </Box>
  );
};

export default Exclusive;
