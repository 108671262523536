import { AccountCircle } from "@mui/icons-material";
import {
  Box,
  ButtonBase,
  Container,
  Grid,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  ContentComponent,
  ContentElement,
  ImagesElement,
  LineUpElements,
  MusicSetElement,
  SoundCloudElement,
} from "../data/bll";
import { getEventDataFromContract, getEventDataFromId } from "../logic/Cvt";
import { handleGAButtonClick } from "../shared:0.0.2/analytics/GA";
import { EventContext } from "../shared:0.0.2/frame/EventContextProvider";
import { blackTheme } from "../shared:0.0.2/themes/blackTheme";
import { EventData, EventState } from "../shared:0.0.2/types/dto";
import Discount from "./contentElements/Discount";
import ImageViewer from "./contentElements/ImageViewer";
import SoundPlayer from "./contentElements/SoundPlayer";
import LoadingScreen from "./lib/LoadingScreen";
import LineUp from "./contentElements/LineUp";
import SoundCloudComponent from "./contentElements/SoundCloudComponent";

export const Backstage = () => {
  const [contentElements, setContentElements] = React.useState<
    ContentElement[]
  >([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { event, setEvent } = React.useContext(EventContext);
  const [valid, setValid] = React.useState<boolean>();

  React.useEffect(() => {
    //TODO: ignore display when we go to invalid path, otherwise on reload this will destroy everything we are just hacking at this point

    let _eventId = searchParams.get("eventId");
    if (!event && _eventId) {
      getEventDataFromId(_eventId)
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(response.status + ": " + response.statusText);
          } else {
            return response.json();
          }
        })
        .then((_eventData: EventData) => {
          if (
            _eventData.eventState.toString() === EventState[EventState.NONE]
          ) {
            setEvent(_eventData);
          }
        })
        .catch((error) =>
          navigate("/error", {
            state: {
              statusCode: 400,
              msg: "The event information for the Ticket could not be retrieved, indicating that the Ticket is not issued by MintHarbor",
            },
          })
        );
    }

    let _contractAddress = searchParams.get("contractAddress");
    if (!event && _contractAddress) {
      getEventDataFromContract(_contractAddress)
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(response.status + ": " + response.statusText);
          } else {
            return response.json();
          }
        })
        .then((_eventData: EventData) => {
          if (
            _eventData.eventState.toString() === EventState[EventState.NONE]
          ) {
            setEvent(_eventData);
          }
        })
        .catch((error) =>
          navigate("/error", {
            state: {
              statusCode: 400,
              msg: "The event information for the Ticket could not be retrieved, indicating that the Ticket is not issued by MintHarbor",
            },
          })
        );
    }
  }, []);

  React.useEffect(() => {
    if (event) {
      fetch(
        process.env.REACT_APP_STATIC_SERVER +
          "/" +
          event.contractAddress +
          "/backstage/contentElements.json"
      )
        .then((response) => response.json())
        .then((data) => {
          setContentElements(data);
        });
    }
  }, [event]);

  if (!event || contentElements.length === 0) {
    return <LoadingScreen></LoadingScreen>;
  }

  const getContentElement = ({
    component,
    data,
    exclusive,
  }: ContentElement) => {
    switch (component) {
      case ContentComponent.SOUNDCLOUD:
        console.log("soundlcoud");
        return (
          <SoundCloudComponent
            {...(data as SoundCloudElement)}
          ></SoundCloudComponent>
        );
      case ContentComponent.LINE_UP:
        return <LineUp {...(data as LineUpElements)}></LineUp>;
      case ContentComponent.MUSIC_SET:
        return (
          <SoundPlayer {...(data as MusicSetElement)} exclusive={exclusive} />
        );
      case ContentComponent.IMAGES:
        return (
          <ImageViewer
            {...(data as ImagesElement)}
            exclusive={exclusive}
          ></ImageViewer>
        );
      //return <ImagesComponent data={data as ImagesElement} />;
      case ContentComponent.DISCOUNT:
        return (
          <Discount eventTitle={event.title} exclusive={exclusive}></Discount>
        );
      //return <UniqueNftComponent data={data as UniqueNftElement} />;
      default:
        return null;
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        pt: { xs: 5 },
        pb: 5,
      }}
    >
      <Box sx={{ backgroundColor: "background.default" }}>
        <ThemeProvider theme={blackTheme}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <ButtonBase
              onClick={() => {
                handleGAButtonClick("User", "Clicked Account");
                navigate("/v0/Account", { state: { from: location } });
              }}
            >
              <AccountCircle sx={{ height: 60, width: 60 }}></AccountCircle>
            </ButtonBase>
          </Box>
        </ThemeProvider>
      </Box>
      <Typography variant="h5" mt={3}>
        Your Backstage Content for {event?.title}
      </Typography>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        {contentElements.map((item, key) => (
          <ThemeProvider theme={blackTheme} key={key}>
            <Grid item xs={12}>
              {getContentElement(item)}
            </Grid>
          </ThemeProvider>
        ))}
      </Grid>
    </Container>
  );
};
