import { Redeem } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { ItemHeader } from "./ItemHeader";
import { Cvt } from "../../data/dto";

type SummaryProps = {
  isActive: boolean;
  result: Cvt | undefined;
  isMobile: boolean;
};

export const EntranceTicketSummary = (props: SummaryProps) => {
  function downloadImage(signature: string) {
    if (props.result && props.result.chain && props.result.cvtId) {
      let _nft: any = { ...props.result };
      let _cvtId = _nft.cvtId;
      delete _nft.cvtId;
      _nft = { ..._nft, cvtId: _cvtId };
      fetch(process.env.REACT_APP_PILOT_BE_URL + "/v0/tickets/entrance/pdf", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(_nft),
      }).then((response) => {
        //TODO: i don t know what i am doing haha
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "ticket" + props?.result?.cvtId + ".pdf";
          a.click();
        });
      });
    }
  }

  return (
    <Box sx={{ width: "100%" }}>
      <ItemHeader
        activeDesc="Order Summary"
        isActive={props.isActive}
        notActiveDesc={"Order Summary"}
      ></ItemHeader>
      {props.isActive ? (
        <Box sx={{ p: 3, textAlign: "center" }}>
          <Redeem sx={{ fontSize: 100 }}></Redeem>
          <Typography variant="h6" mt={4}>
            Your Entrance Ticket has been sent to you via email, alternatively
            download it now!
          </Typography>
          <Button
            fullWidth={true}
            variant={"contained"}
            onClick={() => downloadImage("")}
            sx={{ mt: 2 }}
          >
            download ticket
          </Button>
        </Box>
      ) : null}
    </Box>
  );
};
