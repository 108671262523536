import { Box, ThemeProvider, Typography } from "@mui/material";
import { blackTheme } from "../../shared:0.0.2/themes/blackTheme";
import { SoundCloudElement } from "../../data/bll";

type Props = {
  trackId: string;
};

const SoundCloudComponent = ({
  trackId,
  description,
  name,
}: SoundCloudElement) => {
  return (
    <ThemeProvider theme={blackTheme}>
      <Box
        sx={{
          mt: 2,
          borderRadius: 5,
          backgroundColor: "background.default",
          overflow: "hidden",
          p: 1,
        }}
      >
        <Box
          sx={{
            height: 200,
            width: "100%",
            overflow: "hidden",
            borderRadius: 5,
          }}
        >
          <iframe
            width="102%"
            height="200"
            allow="autoplay"
            src={
              "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/" +
              trackId +
              "&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
            }
          ></iframe>
        </Box>
        {name ? (
          <Box sx={{ p: 1 }}>
            <Typography variant="h2">{name}</Typography>
          </Box>
        ) : null}
        {description ? (
          <Box sx={{ p: 1 }}>
            <Typography variant="body2">{description}</Typography>
          </Box>
        ) : null}
      </Box>
    </ThemeProvider>
  );
};

export default SoundCloudComponent;
