import { createTheme, ThemeOptions } from "@mui/material/styles";

export const defaultThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#52784f",
    },
    secondary: {
      main: "#3B3B3B",
      light: "#3B3B3B",
      contrastText: "#FFFFFF",
    },
    background: {
      default: "#FDF7F2",
      paper: "#FDF7F2",
    },
    text: {
      primary: "#000000",
      secondary: "#3B3B3B",
    },
  },
  typography: {
    fontFamily: "Montserrat, sans-serif",
    h1: {
      fontWeight: 600,
      fontSize: 55,
    },
    h3: {
      fontWeight: 600,
      fontSize: 45,
    },
    h5: {
      fontWeight: 600,
      fontSize: 25,
    },
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        color: "transparent",
        elevation: 0,
      },
    },
  },
};

export const defaultTheme = createTheme(defaultThemeOptions);
