import { Box, Grid, Link, ThemeProvider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NFTAttribute, NFTType } from "../../data/bll";
import { whiteTheme } from "../../shared:0.0.2/themes/whiteTheme";
import MediaShareButton from "../../shared:0.0.2/util/MediaShareButton";
import { handleGAButtonClick } from "../../shared:0.0.2/analytics/GA";
import Exclusive from "./Exclusive";

export interface NFTViewerProps {
  type: NFTType;
  imageUrl: string;
  smartContractAddress: string;
  cvtId: number;
  jsonUrl: string;
}

const NFTViewer = ({
  type,
  imageUrl,
  smartContractAddress,
  cvtId,
  jsonUrl,
  exclusive,
}: NFTViewerProps & { exclusive: boolean }) => {
  const [attributes, setAttributes] = useState<NFTAttribute[]>([]);

  useEffect(() => {
    console.log(jsonUrl);
    fetch(jsonUrl)
      .then((response) => response.json())
      .then((data) => setAttributes(data.attributes));
  }, [jsonUrl]);

  return (
    <Box
      sx={{
        pt: 2,
        pb: 2,
        borderRadius: 5,
        backgroundColor: "background.default",
      }}
    >
      <Box
        component={"img"}
        alt="NFT"
        src={imageUrl}
        sx={{ width: "100%", height: "auto" }}
      ></Box>
      <Box sx={{ pl: 2, pr: 2, pt: 1 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {type === NFTType.UNIQUE ? (
            <Typography alignSelf={"center"} variant="h2">
              Your Unique Artwork #{cvtId}
            </Typography>
          ) : (
            <Typography alignSelf={"center"} variant="h2">
              Poster Artwork
            </Typography>
          )}
          <MediaShareButton
            download={true}
            url={imageUrl}
            width={25}
            height={25}
            iconProps={{ color: "secondary" }}
          ></MediaShareButton>
        </Box>
        <Grid container spacing={2} sx={{ mt: 0 }}>
          {attributes.map((attribute, index) => (
            <Grid item xs={6} key={index}>
              <ThemeProvider theme={whiteTheme}>
                <Box
                  sx={{
                    p: 1,
                    backgroundColor: "background.default",
                    borderRadius: 2,
                  }}
                >
                  <Typography>{attribute.trait_type}</Typography>
                  <Typography fontWeight={"bold"}>{attribute.value}</Typography>
                </Box>
              </ThemeProvider>
            </Grid>
          ))}
        </Grid>
        <Typography variant="body2" sx={{ mt: 1 }}>
          View on{" "}
          <Link
            onClick={() => handleGAButtonClick("User", "View Polygon")}
            href={"http://polygonscan.com/address/" + smartContractAddress}
          >
            Polygonscan
          </Link>{" "}
          and store it in your own Wallet by converting the Ticket to an NFT.{" "}
        </Typography>
      </Box>
      <Box sx={{ pr: 2, pl: 2 }}>
        {exclusive ? <Exclusive></Exclusive> : null}
      </Box>
    </Box>
  );
};

export default NFTViewer;
