import { Box } from "@mui/material";
import { ethers } from "ethers";
import MobileDetect from "mobile-detect";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MintCvt } from "../data/dto";
import { searchParamsToMintCvt } from "../logic/Cvt";
import LoadingScreen from "./lib/LoadingScreen";
import { ConvertTicket } from "./mintItems/ConvertTicket";
import { MetaMaskChain } from "./mintItems/MetaMaskChain";
import { MetaMaskDetection } from "./mintItems/MetaMaskDetection";
import { Summary } from "./mintItems/Summary";
import { VerifyRequest } from "./mintItems/VerifyRequest";

export const MintOracle = () => {
  let md = new MobileDetect(window.navigator.userAgent);
  //Browser Detection
  const [isMobile, setIsMobile] = React.useState<boolean>(
    md.os() === "iOS" || md.os() === "AndroidOS"
  );

  //MetaMask Detection
  const [provider, setProvider] = React.useState(
    (window as any).ethereum !== undefined
      ? new ethers.providers.Web3Provider((window as any).ethereum, "any")
      : undefined
  );
  const [cvtVerified, setCvtVerified] = React.useState<boolean>(false);

  //MetaMask Chain Check
  const [isPolygon, setIsPolygon] = React.useState<boolean>(false);

  //Get Order Results
  const [orderResult, setOrderResult] = React.useState<any>();

  //cvt info
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [cvt, setMintCvt] = React.useState<MintCvt>();

  React.useEffect(() => {
    let _mintCvt = searchParamsToMintCvt(searchParams);
    if (_mintCvt) {
      setMintCvt(_mintCvt);
    } else {
      navigate("/error", {
        state: {
          statusCode: 400,
          msg: "The Ticket Information is invalid as it could not be resolved",
        },
      });
    }
  }, [searchParams, navigate]);

  let metaMaskDetectionIsActive = cvtVerified && provider === undefined;
  let metaMaskChainIsActive = !metaMaskDetectionIsActive && !isPolygon;
  let convertIsActive = !metaMaskChainIsActive && isPolygon && !orderResult;
  let summaryIsActive = !convertIsActive && orderResult;

  if (isMobile === undefined || !cvt) {
    return <LoadingScreen></LoadingScreen>;
  }

  return (
    <Box
      style={{
        borderRadius: 20,
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: "#52784F",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflow: "hidden",
        paddingTop: 1,
        backgroundColor: "white",
        marginBottom: 20,
      }}
    >
      <VerifyRequest
        isActive={!cvtVerified}
        setVerified={setCvtVerified}
        cvt={cvt}
      ></VerifyRequest>
      <MetaMaskDetection
        isMobile={isMobile}
        isActive={metaMaskDetectionIsActive}
        os={md.os()}
      ></MetaMaskDetection>
      <MetaMaskChain
        provider={provider}
        setChainIsActive={setIsPolygon}
        isActive={metaMaskChainIsActive}
      ></MetaMaskChain>
      <ConvertTicket
        provider={provider}
        isActive={convertIsActive}
        cvt={cvt}
        setOrderResult={setOrderResult}
      ></ConvertTicket>
      <Summary
        isActive={summaryIsActive}
        cvt={cvt}
        orderResult={orderResult}
        isMobile={isMobile}
      ></Summary>
    </Box>
  );
};
