import { Box, ButtonBase, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { LineUpElement, LineUpElements } from "../../data/bll";
import { Carousel } from "react-responsive-carousel";
import "./CarouselStyles.css";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import LineUpComponent from "./LineUpComponent";
import { start } from "repl";

const LineUp = ({
  stages,
  dayElements,
  startDate,
  endDate,
}: LineUpElements) => {
  stages = ["All Stages", ...stages];

  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const days =
    (new Date(endDate).getTime() - new Date(startDate).getTime()) /
      (1000 * 3600 * 24) +
    1;
  const getFestivalDay = () => {
    const _startDate = new Date(startDate); // replace with your start date
    const _endDate = new Date(endDate); // replace with your end date
    const currentDate = new Date();
    let targetDate;
    if (currentDate < _startDate) {
      targetDate = _startDate;
    } else if (currentDate > _endDate) {
      targetDate = _endDate;
    } else {
      targetDate = new Date(currentDate);
      const hour = currentDate.getHours();
      if (hour < 10) {
        targetDate.setDate(targetDate.getDate() - 1);
      }
    }

    return targetDate.getDay();
  };

  const currDay = getFestivalDay();

  const [selectedDay, setSelectedDay] = React.useState<number>(currDay);
  //const [elements, setElements] = React.useState<LineUpElement[]>();
  const [selectedItem, setSelectedItem] = React.useState<number>(0);

  console.log(selectedDay);
  console.log(currDay + days);
  let elements = dayElements.filter(
    (elem) => elem.day === dayNames[selectedDay]
  )[0].elements;

  if (!elements) {
    return <CircularProgress></CircularProgress>;
  }

  return (
    <Box
      sx={{
        borderRadius: 5,
        overflow: "hidden",
        backgroundColor: "background.default",
      }}
    >
      <Carousel
        onChange={(index) => {
          setSelectedItem(index);
        }}
        selectedItem={selectedItem}
        showThumbs={false}
        swipeable={false}
        renderArrowNext={() => null}
        renderArrowPrev={() => null}
      >
        {stages.map((stage, key) => (
          <Box
            key={key}
            sx={{
              height: "50vh",
              width: "100%",
              p: 2,
              overflowY: "auto",
            }}
          >
            {elements
              .filter(
                (element) => stage === "All Stages" || element.stage === stage
              )
              .map((element, key) => {
                return (
                  <Box key={key}>
                    <LineUpComponent
                      lineUpElement={element}
                      isAll={stage === "All Stages"}
                    ></LineUpComponent>
                  </Box>
                );
              })}
          </Box>
        ))}
      </Carousel>
      <Box
        sx={{
          p: 2,
          width: "100%",
          justifyContent: "space-between",
          flexDirection: "row",
          display: "flex",
        }}
      >
        <Box>
          <Typography variant="h2">{stages[selectedItem]}</Typography>
          <Typography variant="body2">Line Up</Typography>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            {selectedItem !== 0 ? (
              <ButtonBase onClick={() => setSelectedItem(selectedItem - 1)}>
                <ArrowBackIos
                  style={{ height: 20, width: 20 }}
                  color="secondary"
                ></ArrowBackIos>
              </ButtonBase>
            ) : (
              <Box height={20} width={20}></Box>
            )}
            <Typography mr={0.3}>Stages</Typography>
            {selectedItem !== stages.length - 1 ? (
              <ButtonBase onClick={() => setSelectedItem(selectedItem + 1)}>
                <ArrowForwardIos
                  style={{ height: 20, width: 20 }}
                  color="secondary"
                ></ArrowForwardIos>
              </ButtonBase>
            ) : (
              <Box height={20} width={20}></Box>
            )}
          </Box>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            {selectedDay !== currDay % 6 ? (
              <ButtonBase
                onClick={() => setSelectedDay((selectedDay - 1 + 7) % 7)}
              >
                <ArrowBackIos
                  style={{ height: 20, width: 20 }}
                  color="secondary"
                ></ArrowBackIos>
              </ButtonBase>
            ) : (
              <Box height={20} width={20}></Box>
            )}
            <Typography mr={0.3}>{dayNames[selectedDay]}</Typography>
            {selectedDay !== (currDay + days - 1) % 7 ? (
              <ButtonBase
                onClick={() => setSelectedDay((selectedDay + 1 + 7) % 7)}
              >
                <ArrowForwardIos
                  style={{ height: 20, width: 20 }}
                  color="secondary"
                ></ArrowForwardIos>
              </ButtonBase>
            ) : (
              <Box height={20} width={20}></Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LineUp;
