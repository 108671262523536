import { Button, Grid, Link, Typography } from "@mui/material";
import React from "react";
import { Cvt } from "../data/dto";

type Props = {
  cvt: Cvt;
  collapsed: boolean;
};

export const CvtInfo = ({ cvt, collapsed }: Props) => {
  const [collapse, setCollapsed] = React.useState(collapsed);

  return (
    <Grid
      container
      spacing={0}
      mt={2}
      sx={{
        borderColor: "secondary.main",
        border: 2,
        borderRadius: 5,
        overflow: "hidden",
      }}
    >
      {!collapse ? (
        <Grid item xs={4} sx={{ backgroundColor: "secondary.main", p: 1 }}>
          <Typography textAlign={"right"} color="secondary.contrastText">
            <strong>Ticket ID</strong>
          </Typography>
        </Grid>
      ) : null}
      {!collapse ? (
        <Grid item xs={8} p={1}>
          <Typography>{cvt.cvtId}</Typography>
        </Grid>
      ) : null}
      {!collapse ? (
        <Grid item xs={4} sx={{ backgroundColor: "secondary.main", p: 1 }}>
          <Typography textAlign={"right"} color="secondary.contrastText">
            <strong>Client ID</strong>
          </Typography>
        </Grid>
      ) : null}
      {!collapse ? (
        <Grid item xs={8} p={1}>
          <Typography noWrap>{cvt.clientId}</Typography>
        </Grid>
      ) : null}
      {!collapse ? (
        <Grid item xs={4} sx={{ backgroundColor: "secondary.main", p: 1 }}>
          <Typography textAlign={"right"} color="secondary.contrastText">
            <strong>SmartContractAddress</strong>
          </Typography>
        </Grid>
      ) : null}
      {!collapse ? (
        <Grid item xs={8} p={1}>
          <Typography noWrap>{cvt.smartContractAddress}</Typography>
        </Grid>
      ) : null}
      {!collapse ? (
        <Grid item xs={4} sx={{ backgroundColor: "secondary.main", p: 1 }}>
          <Typography textAlign={"right"} color="secondary.contrastText">
            <strong>Ticket Signature</strong>
          </Typography>
        </Grid>
      ) : null}
      {!collapse ? (
        <Grid item xs={8} p={1}>
          <Typography noWrap>{cvt.signature}</Typography>
        </Grid>
      ) : null}
      {!collapse ? (
        <Grid item xs={4} sx={{ backgroundColor: "secondary.main", p: 1 }}>
          <Typography textAlign={"right"} color="secondary.contrastText">
            <strong>View on Polygon</strong>
          </Typography>
        </Grid>
      ) : null}
      {!collapse ? (
        <Grid item xs={8} p={1}>
          <Link
            href={"https://polygonscan.com/address/" + cvt.smartContractAddress}
          >
            <Typography noWrap>
              https://polygonscan.com/address/{cvt.smartContractAddress}
            </Typography>
          </Link>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={() => (collapse ? setCollapsed(false) : setCollapsed(true))}
          sx={{
            width: "100%",
            borderRadius: 0,
            backgroundColor: "secondary.main",
          }}
        >
          {collapse ? "Ticket Info" : "Show Less"}
        </Button>
      </Grid>
    </Grid>
  );
};
