import { ethers } from "ethers";
import { URLSearchParams } from "url";
import { Cvt, MintCvt, NFT, Voucher } from "../data/dto";

export const searchParamsToMintCvt = (searchParams: URLSearchParams) => {
  let cvt = searchParamsToCvt(searchParams);
  if (!cvt) {
    return null;
  }
  let mintCvt = cvt as MintCvt;
  let _secret = searchParams.get("secret");
  if (_secret) {
    mintCvt.secret = _secret;
    return mintCvt;
  }
  return null;
};

export const searchParamsToCvt = (searchParams: URLSearchParams) => {
  let _chain = searchParams.get("chain");
  let _smartContractAddress = searchParams.get("smartContractAddress");
  let _cvtId = Number(searchParams.get("cvtId"));
  let _clientId = searchParams.get("clientId");
  let _signature = searchParams.get("signature");
  if (
    _chain &&
    _smartContractAddress &&
    _cvtId !== null &&
    _clientId &&
    _signature
  ) {
    //TODO: incorporate contract version
    let cvt: Cvt = {
      chain: _chain,
      smartContractAddress: _smartContractAddress,
      cvtId: _cvtId,
      clientId: _clientId,
      signature: _signature,
      smartContractVersion: "atlas",
    };
    return cvt;
  }
  throw new Error("Could not create CVT from Request");
};

export async function constructVoucher(
  mintCvt: MintCvt,
  recipient: string
): Promise<Voucher> {
  let encodedVoucher = ethers.utils.defaultAbiCoder.encode(
    ["address", "uint32"],
    [recipient, mintCvt.cvtId]
  );
  let hashedVoucher = ethers.utils.arrayify(
    ethers.utils.keccak256(encodedVoucher)
  );
  let signer = new ethers.Wallet(mintCvt.secret);
  let signature = await signer.signMessage(hashedVoucher);
  let voucher: Voucher = {
    cvtId: mintCvt.cvtId,
    signature: signature,
    recipient: recipient,
  };
  return voucher;
}

export function verifyCvt(cvt: Cvt) {
  let strippedCvt = stripCvt(cvt);
  return fetch(process.env.REACT_APP_PILOT_BE_URL + "/v0/oracle/verify", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      signature: cvt?.signature,
      cvtId: cvt?.cvtId,
      smartContractAddress: cvt.smartContractAddress,
      clientId: cvt.clientId,
    }),
  });
}

export function verifyEntranceSignature(cvt: Cvt) {
  let strippedCvt = stripCvt(cvt);
  return fetch(process.env.REACT_APP_ORACLE_BE + "/v0/oracle/verify/entrance", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(strippedCvt),
  });
}

export function getEventDataFromContract(smartContractAddress: string) {
  return fetch(
    process.env.REACT_APP_PILOT_BE_URL +
      "/v0/event/smartContract?smartContractAddress=" +
      smartContractAddress
  );
}

export function getEventData(cvt: Cvt) {
  let strippedCvt = stripCvt(cvt);
  return fetch(
    process.env.REACT_APP_PILOT_BE_URL +
      "/v0/event/smartContract?smartContractAddress=" +
      strippedCvt.smartContractAddress
  );
}

export function getEventDataFromId(eventId: String) {
  return fetch(
    process.env.REACT_APP_PILOT_BE_URL + "/v0/event?eventId=" + eventId
  );
}

export async function mintCvt(cvt: MintCvt, voucher: Voucher) {
  let strippedCvt = stripCvt(cvt);
  let _body = { ...strippedCvt, voucher: voucher };
  return fetch(process.env.REACT_APP_ORACLE_BE + "/v0/oracle/mint", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(_body),
  });
}

export async function ownerOfNFT(nft: NFT) {
  return fetch(process.env.REACT_APP_ORACLE_BE + "/v0/oracle/ownerOfNFT", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(nft),
  });
}

export function stripCvt(cvt: Cvt): Cvt {
  //TODO: this function is kind of a security risk
  return {
    cvtId: cvt.cvtId,
    clientId: cvt.clientId,
    smartContractAddress: cvt.smartContractAddress,
    smartContractVersion: cvt.smartContractVersion,
    chain: cvt.chain,
    signature: cvt.signature,
  };
}
